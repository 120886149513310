package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String
import kotlin.collections.Map

public object ResStrings {
  private val baseLocale: StringsEn = StringsEn

  private val locales: Map<String, Strings> = mapOf("ko" to StringsKo, "ja" to StringsJa, "en" to
      StringsEn, "fr" to StringsFr, "zh" to StringsZh, "hant" to StringsHant)

  public val locale: String
    get() = locales[getCurrentLanguageCode()]?.locale ?: baseLocale.locale

  public val app_name: String
    get() = locales[getCurrentLanguageCode()]?.app_name ?: baseLocale.app_name

  public val home: String
    get() = locales[getCurrentLanguageCode()]?.home ?: baseLocale.home

  public val about: String
    get() = locales[getCurrentLanguageCode()]?.about ?: baseLocale.about

  public val privacy: String
    get() = locales[getCurrentLanguageCode()]?.privacy ?: baseLocale.privacy

  public val user_agreement: String
    get() = locales[getCurrentLanguageCode()]?.user_agreement ?: baseLocale.user_agreement

  public val terms_of_service: String
    get() = locales[getCurrentLanguageCode()]?.terms_of_service ?: baseLocale.terms_of_service

  public val privacy_policy: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy ?: baseLocale.privacy_policy
}
