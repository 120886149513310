package baiyan.app.pages.ja

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun PrivacyPage() {
    baiyan.app.components.layouts.MarkdownLayout("Privacy") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("バイエンのプライバシーポリシー") }) {
            org.jetbrains.compose.web.dom.Text("バイエンのプライバシーポリシー")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("このプライバシーポリシーは、当社のモバイルアプリケーションおよび関連サービスを使用する際に、バイエンがあなたに関する情報をどのように収集、利用、開示するかを説明します。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("1-情報の収集") }) {
            org.jetbrains.compose.web.dom.Text("1. 情報の収集")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("バイエンアプリを使用する際、私たちはあなたから、あるいはあなたについて様々な種類の情報を収集します：")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("個人情報") }) {
            org.jetbrains.compose.web.dom.Text("個人情報")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("登録およびプロファイル情報：")
                }
                org.jetbrains.compose.web.dom.Text(" アカウントを作成、プロファイルを更新、または設定をする際に、名前、メールアドレス、言語の好みなどの個人情報を収集します。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("利用情報") }) {
            org.jetbrains.compose.web.dom.Text("利用情報")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("アプリとのインタラクション：")
                }
                org.jetbrains.compose.web.dom.Text(" アプリとのやりとり、閲覧したページ、視聴したビデオ、アプリ内でのその他の行動に関する情報を収集します。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("技術情報およびデバイス情報") }) {
            org.jetbrains.compose.web.dom.Text("技術情報およびデバイス情報")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("デバイス情報：")
                }
                org.jetbrains.compose.web.dom.Text(" アプリにアクセスするために使用するモバイルデバイスについて、ハードウェアモデル、オペレーティングシステムのバージョン、モバイルネットワーク情報などの情報を収集します。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("クッキーと追跡技術") }) {
            org.jetbrains.compose.web.dom.Text("クッキーと追跡技術")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("あなたの利用状況や好みに関する情報を収集するためにクッキーやその他の追跡技術を使用します。クッキーを拒否するようにデバイスを設定することができますが、その場合、アプリの一部が正常に機能しないことがあります。")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("2-情報の利用") }) {
            org.jetbrains.compose.web.dom.Text("2. 情報の利用")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("収集した情報は、以下のようなさまざまな方法で利用されます：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("バイエンアプリの提供、維持、改善のため；")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("あなたが当社のアプリをどのように使用しているかを理解し分析し、新しい製品、サービス、機能を開発するため；")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("顧客サービスを提供するため、またはアプリに関連する更新やその他の情報を提供するため、マーケティングやプロモーション目的で、直接またはパートナーを通じてあなたとコミュニケーションをとるため；")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("通知を送信するため；")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("3-情報の共有") }) {
            org.jetbrains.compose.web.dom.Text("3. 情報の共有")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("以下のような方法で、収集した情報を共有することがあります：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("サービスプロバイダー：")
                }
                org.jetbrains.compose.web.dom.Text(" サービス提供の手助けや分析サービスを提供するために、第三者のベンダーやサービスプロバイダーと情報を共有することがあります。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("法的要件：")
                }
                org.jetbrains.compose.web.dom.Text(" 法律により要求された場合、または善意の信念に基づき、法的義務に従うため、当社の権利や財産を保護し防衛するため、アプリのユーザーの個人の安全を守るため、または法的責任から保護するた")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("めに情報を開示することがあります。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("4-セキュリティ") }) {
            org.jetbrains.compose.web.dom.Text("4. セキュリティ")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("私たちは、あなたに関する情報を失われたり、盗まれたり、悪用されたり、不正アクセスや開示、変更、破壊から保護するための合理的な措置を講じています。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("5-国際転送") }) {
            org.jetbrains.compose.web.dom.Text("5. 国際転送")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("バイエンはグローバルに運営されており、収集した情報を世界中のさまざまな国に処理、保存、転送することがあります。これにはあなたの国外も含まれます。以下のようにこれらの転送を取り扱います：")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("転送メカニズム") }) {
            org.jetbrains.compose.web.dom.Text("転送メカニズム")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("法的遵守：")
                }
                org.jetbrains.compose.web.dom.Text(" 適用されるデータ保護法に従って個人情報を転送します。欧州委員会によって承認された標準契約条項、個人の同意（必要な場合）、およびその他の法的に認められたメカニズムを利用して、個人データの合法的な転送を保証します。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("データ保護") }) {
            org.jetbrains.compose.web.dom.Text("データ保護")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("安全対策：")
                }
                org.jetbrains.compose.web.dom.Text(" あなたの個人情報を保護するため、厳格な安全対策を適用しています。これには暗号化や安全なサーバー施設の使用が含まれます。あなたの情報を処理する第三者もこれらの安全対策を実施し維持することを約束します。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("ユーザーの権利") }) {
            org.jetbrains.compose.web.dom.Text("ユーザーの権利")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("アクセスとコントロール：")
                }
                org.jetbrains.compose.web.dom.Text(" あなたの情報が処理される場所に関わらず、あなたの個人データにアクセスし、修正または削除する権利を保持します。要求や懸念については、当社の連絡先情報セクションに記載されている情報を使用して直接お問い合わせください。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("規制監督") }) {
            org.jetbrains.compose.web.dom.Text("規制監督")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("地元法律の遵守：")
                }
                org.jetbrains.compose.web.dom.Text(" 当社は定期的にデータ転送の実践を見直し、地元の法的要件に合致することを確認し、必要に応じて地元のデータ保護当局と協力します。これには、私たちが運営する地域でのデータ転送を規制する特定の法律や規制の遵守も含まれます。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("プライバシーへのコミットメント") }) {
            org.jetbrains.compose.web.dom.Text("プライバシーへのコミットメント")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("プライバシーシールドフレームワーク：")
                }
                org.jetbrains.compose.web.dom.Text(" EUおよびスイスから米国へのデータ転送については、プライバシーシールドフレームワークに従い、確立されたEUのデータ保護要件に一致するデータ保護レベルを維持します。")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("これらの地元および国際的なデータ保護法に従うことで、あなたの情報が処理される場所にかかわらず、尊重され、責任を持って取り扱われることを保証します。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
    }
}
