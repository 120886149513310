package baiyan.app.pages.fr

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    baiyan.app.components.layouts.MarkdownLayout("About") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("à-propos-de-baiyan") }) {
            org.jetbrains.compose.web.dom.Text("À propos de Baiyan")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan est une application novatrice propulsée par l'IA qui transforme la façon dont les utilisateurs apprennent les langues à travers des contenus vidéo, la rendant aussi captivante et addictive que de naviguer sur TikTok.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("apprendre") }) {
            org.jetbrains.compose.web.dom.Text("Apprendre")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan utilise des vidéos courtes et engageantes pour enseigner aux utilisateurs les prononciations authentiques dans différentes langues, créant une expérience d'apprentissage immersive qui est à la fois efficace et agréable.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("caractéristiques") }) {
            org.jetbrains.compose.web.dom.Text("Caractéristiques")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("sous-titres-générés-par-ia") }) {
            org.jetbrains.compose.web.dom.Text("Sous-titres générés par IA")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan intègre une technologie IA avancée pour générer automatiquement des sous-titres précis pour chaque mot dans les vidéos. Cette fonctionnalité améliore la compréhension et la rétention en permettant aux utilisateurs de voir l'orthographe et le sens des mots tels qu'ils sont prononcés.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("support-multilingue") }) {
            org.jetbrains.compose.web.dom.Text("Support multilingue")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("La plateforme prend en charge une large gamme de langues, offrant aux utilisateurs la possibilité d'apprendre plusieurs langues dans un environnement intuitif.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("interface-utilisateur-conviviale") }) {
            org.jetbrains.compose.web.dom.Text("Interface utilisateur conviviale")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Conçue avec la simplicité à l'esprit, Baiyan propose une interface utilisateur conviviale qui rend l'apprentissage des langues accessible à tous. Les utilisateurs peuvent facilement naviguer à travers différentes vidéos et suivre leur progression.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("outils-d-engagement") }) {
            org.jetbrains.compose.web.dom.Text("Outils d'engagement")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan inclut divers outils pour maintenir la motivation des utilisateurs, tels que des traceurs de progrès, des quiz interactifs, et des défis quotidiens qui encouragent la pratique régulière.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("technologie") }) {
            org.jetbrains.compose.web.dom.Text("Technologie")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan utilise les dernières technologies IA pour analyser et décomposer le langage des interactions naturelles et quotidiennes capturées en format vidéo, offrant aux utilisateurs un outil d'apprentissage unique et efficace.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("point-de-départ") }) {
            org.jetbrains.compose.web.dom.Text("Point de départ")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan est bien plus qu'une simple application d'apprentissage linguistique ; c'est une plateforme que les utilisateurs peuvent personnaliser et adapter à leur style d'apprentissage. Que vous commenciez de zéro ou cherchiez à améliorer vos compétences linguistiques existantes, Baiyan fournit une base solide pour tous les apprenants.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("exporter-et-engager") }) {
            org.jetbrains.compose.web.dom.Text("Exporter et Engager")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Au fur et à mesure que les utilisateurs progressent, ils peuvent partager leurs réalisations et interagir avec une communauté mondiale d'apprenants. Baiyan ne soutient pas seulement la croissance individuelle mais favorise également un environnement collaboratif où les utilisateurs peuvent échanger des astuces, des insights et des encouragements.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Engagez-vous avec Baiyan dès aujourd'hui et transformez votre expérience d'apprentissage linguistique en un voyage agréable et enrichissant !")
        }
    }
}
