package baiyan.app.pages.ko

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun PrivacyPage() {
    baiyan.app.components.layouts.MarkdownLayout("Privacy") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("바이옌-개인정보-보호-정책") }) {
            org.jetbrains.compose.web.dom.Text("바이옌 개인정보 보호 정책")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("이 개인정보 보호 정책은 모바일 애플리케이션 및 관련 서비스를 사용할 때 바이옌이 어떻게 귀하에 관한 정보를 수집, 사용 및 공개하는지 설명합니다.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("1-정보-수집") }) {
            org.jetbrains.compose.web.dom.Text("1. 정보 수집")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌 앱을 사용할 때, 우리는 귀하에 관한 다양한 유형의 정보를 수집합니다:")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("개인정보") }) {
            org.jetbrains.compose.web.dom.Text("개인정보")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("등록 및 프로필 정보:")
                }
                org.jetbrains.compose.web.dom.Text(" 계정을 생성하거나, 프로필을 업데이트하거나, 선호도를 설정할 때, 귀하의 이름, 이메일 주소 및 언어 선호도와 같은 개인정보를 수집합니다.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("사용-정보") }) {
            org.jetbrains.compose.web.dom.Text("사용 정보")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("앱과의 상호작용:")
                }
                org.jetbrains.compose.web.dom.Text(" 앱과 상호작용하는 방법에 대한 정보를 수집합니다. 이에는 귀하가 본 페이지, 시청한 비디오 및 앱 내에서의 기타 활동이 포함됩니다.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("기술-및-장치-정보") }) {
            org.jetbrains.compose.web.dom.Text("기술 및 장치 정보")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("장치 정보:")
                }
                org.jetbrains.compose.web.dom.Text(" 앱에 액세스하는 데 사용하는 모바일 장치에 대한 정보를 수집합니다. 이에는 하드웨어 모델, 운영 체제 버전 및 모바일 네트워크 정보가 포함됩니다.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("쿠키-및-추적-기술") }) {
            org.jetbrains.compose.web.dom.Text("쿠키 및 추적 기술")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("쿠키 및 기타 추적 기술을 사용하여 귀하의 사용 및 선호도에 대한 정보를 수집합니다. 귀하의 장치를 설정하여 쿠키를 거부할 수 있지만, 그 결과 앱의 일부 기능이 제대로 작동하지 않을 수 있습니다.")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("2-정보의-사용") }) {
            org.jetbrains.compose.web.dom.Text("2. 정보의 사용")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("수집한 정보는 다양한 방식으로 사용됩니다. 여기에는 다음이 포함됩니다:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("바이옌 앱을 제공, 유지 및 개선하기;")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("귀하가 우리의 앱을 어떻게 사용하는지 이해하고 분석하며 새로운 제품, 서비스 및 기능을 개발하기;")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("고객 서비스를 제공하고 앱과 관련된 업데이트 및 기타 정보를 제공하며 마케팅 및 홍보 목적으로 귀하와 직접 또는 우리의 파트너를 통해 소통하기;")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("알림을 보내기;")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("3-정보의-공유") }) {
            org.jetbrains.compose.web.dom.Text("3. 정보의 공유")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("수집한 정보는 다음을 포함한 여러 가지 방법으로 공유할 수 있습니다:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("서비스 제공업체:")
                }
                org.jetbrains.compose.web.dom.Text(" 우리를 대신하여 서비스를 제공하는 제3자 벤더 및 서비스 제공업체와 정보를 공유할 수 있습니다. 예를 들어, 우리의 서비스 제공을 돕거나 분석 서비스를 제공하는 것입니다.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("법적 요구사항:")
                }
                org.jetbrains.compose.web.dom.Text(" 법적 의무를 준수하거나 우리의 권리 또는 재산을 보호하고 방어하며 앱 사용자의 개인 안전을 보호하거나 법적 책임으로부터 보호하기 위해 필요하다고 선의로 믿는 행동을 수행하기 위해 정보를 공개할 수 있습니다.")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("4-보안") }) {
            org.jetbrains.compose.web.dom.Text("4. 보안")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("우리는 귀하에 관한 정보를 손실, 도난, 오용, 무단 접근, 공개, 변경 및 파괴로부터 보호하기 위")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("해 합리적인 조치를 취합니다.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("5-국제-이전") }) {
            org.jetbrains.compose.web.dom.Text("5. 국제 이전")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 글로벌 규모로 운영되며, 귀하에 대해 수집한 정보를 귀하의 국가를 포함하여 전 세계 여러 국가에서 처리, 저장 및 전송할 수 있습니다. 이전을 처리하는 방법은 다음과 같습니다:")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("이전-메커니즘") }) {
            org.jetbrains.compose.web.dom.Text("이전 메커니즘")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("법적 준수:")
                }
                org.jetbrains.compose.web.dom.Text(" 우리는 적용 가능한 데이터 보호 법률을 준수하여 개인 정보를 전송합니다. 우리는 유럽위원회가 승인한 표준 계약 조항, 필요한 경우 개인의 동의 및 기타 법적으로 허용된 메커니즘을 사용하여 개인 데이터의 적법한 전송을 보장합니다.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("데이터-보호") }) {
            org.jetbrains.compose.web.dom.Text("데이터 보호")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("보호 조치:")
                }
                org.jetbrains.compose.web.dom.Text(" 귀하의 개인 정보를 보호하기 위해 엄격한 보호 조치를 적용합니다. 이에는 암호화 및 보안 서버 시설 사용이 포함됩니다. 귀하의 정보를 처리하는 제3자도 이러한 보호 조치를 시행하고 유지하기 위해 동등한 약속을 합니다.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("사용자-권리") }) {
            org.jetbrains.compose.web.dom.Text("사용자 권리")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("접근 및 제어:")
                }
                org.jetbrains.compose.web.dom.Text(" 귀하의 정보가 처리되는 위치와 관계없이, 개인 데이터에 접근, 수정 또는 삭제할 권리를 유지합니다. 요청이나 문의가 있는 경우, 연락처 정보 섹션에 제공된 정보를 사용하여 직접 연락해 주세요.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("규제-감독") }) {
            org.jetbrains.compose.web.dom.Text("규제 감독")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("현지 법률 준수:")
                }
                org.jetbrains.compose.web.dom.Text(" 우리는 정기적으로 데이터 전송 관행을 검토하여 현지 법적 요구사항에 부합하도록 하고 필요에 따라 현지 데이터 보호 기관과 협력합니다. 이는 우리가 운영하는 지역에서 데이터 전송을 관리하는 특정 법률 및 규정을 준수하는 것을 포함합니다.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("개인-정보-보호에-대한-헌신") }) {
            org.jetbrains.compose.web.dom.Text("개인 정보 보호에 대한 헌신")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("프라이버시 실드 프레임워크:")
                }
                org.jetbrains.compose.web.dom.Text(" EU 및 스위스에서 미국으로의 데이터 전송의 경우, 우리는 프라이버시 실드 프레임워크를 준수하여, EU 데이터 보호 요구사항에 일치하는 데이터 보호 수준을 유지합니다.")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("현지 및 국제 데이터 보호 법률을 준수하는 이러한 헌신은 귀하의 정보가 처리되는 곳에 상관없이 존중과 책임감 있게 처리될 것임을 보장합니다.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
    }
}
