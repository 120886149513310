package baiyan.app.pages.ja

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    baiyan.app.components.layouts.MarkdownLayout("About") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("バイエンについて") }) {
            org.jetbrains.compose.web.dom.Text("バイエンについて")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("バイエンは、ビデオコンテンツを通じて言語学習の方法を変革する先進的なAI駆動アプリケーションで、TikTokをスクロールするように魅力的で中毒性があります。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("学習") }) {
            org.jetbrains.compose.web.dom.Text("学習")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("バイエンは、短く魅力的なビデオを活用して、ユーザーに様々な言語の本物の発音を教え、効果的で楽しい没入型学習体験を提供します。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("特徴") }) {
            org.jetbrains.compose.web.dom.Text("特徴")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ai生成字幕") }) {
            org.jetbrains.compose.web.dom.Text("AI生成字幕")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("バイエンは先進的なAI技術を取り入れ、ビデオ内の各単語に対して正確な字幕を自動生成します。この機能は、話される単語のつづりや意味を視覚的に確認できることで、理解と記憶を向上させます。")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("多言語サポート") }) {
            org.jetbrains.compose.web.dom.Text("多言語サポート")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("このプラットフォームは多種多様な言語をサポートし、ユーザーに一つの直感的な環境で複数の言語を学ぶ機会を提供します。")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ユーザーフレンドリーなインターフェース") }) {
            org.jetbrains.compose.web.dom.Text("ユーザーフレンドリーなインターフェース")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("シンプルを心掛けて設計されたバイエンは、誰でもアクセスしやすいユーザーフレンドリーなインターフェースを提供します。ユーザーは異なるビデオを簡単にナビゲートし、進捗を追跡することができます。")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("エンゲージメントツール") }) {
            org.jetbrains.compose.web.dom.Text("エンゲージメントツール")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("バイエンには、進捗状況の追跡、インタラクティブなクイズ、日々のチャレンジなど、ユーザーのモチベーションを維持する様々なツールが含まれています。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("技術") }) {
            org.jetbrains.compose.web.dom.Text("技術")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("バイエンは、ビデオフォーマットで捉えた自然な日常のやり取りから言語を分析し分解する最新のAI技術を活用し、ユーザーに独特で効果的な学習ツールを提供します。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("出発点") }) {
            org.jetbrains.compose.web.dom.Text("出発点")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("バイエンは単なる言語学習アプリ以上のものです。それはユーザーが自分の学習スタイルにカスタマイズし適応できるプラットフォームです。ゼロから始める人も、既存の言語スキルを向上させたい人も、バイエンは全ての学習者に強固な基盤を提供します。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("輸出と参加") }) {
            org.jetbrains.compose.web.dom.Text("輸出と参加")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("ユーザーが進歩するにつれて、彼らは自分の成果を共有し、世界中の学習者コミュニティと交流することができます。バイエンは個々の成長をサポートするだけでなく、ユーザーがヒントや洞察、励ましを交換できる協力的な環境も育てます。")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("今日からバイエンと交流を始めて、言語学習体験を楽しく充実したものに変えましょう！")
        }
    }
}
