import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { baiyan.app.pages.HomePage() }
        ctx.router.register("/about") { baiyan.app.pages.AboutPage() }
        ctx.router.register("/en/about") { baiyan.app.pages.en.AboutPage() }
        ctx.router.register("/en/privacy") { baiyan.app.pages.en.PrivacyPage() }
        ctx.router.register("/en/user-agreement") { baiyan.app.pages.en.UserAgreementPage() }
        ctx.router.register("/fr/about") { baiyan.app.pages.fr.AboutPage() }
        ctx.router.register("/fr/privacy") { baiyan.app.pages.fr.PrivacyPage() }
        ctx.router.register("/fr/user-agreement") { baiyan.app.pages.fr.UserAgreementPage() }
        ctx.router.register("/hant/about") { baiyan.app.pages.hant.AboutPage() }
        ctx.router.register("/hant/privacy") { baiyan.app.pages.hant.PrivacyPage() }
        ctx.router.register("/hant/user-agreement") { baiyan.app.pages.hant.UserAgreementPage() }
        ctx.router.register("/ja/about") { baiyan.app.pages.ja.AboutPage() }
        ctx.router.register("/ja/privacy") { baiyan.app.pages.ja.PrivacyPage() }
        ctx.router.register("/ja/user-agreement") { baiyan.app.pages.ja.UserAgreementPage() }
        ctx.router.register("/ko/about") { baiyan.app.pages.ko.AboutPage() }
        ctx.router.register("/ko/privacy") { baiyan.app.pages.ko.PrivacyPage() }
        ctx.router.register("/ko/user-agreement") { baiyan.app.pages.ko.UserAgreementPage() }
        ctx.router.register("/privacy") { baiyan.app.pages.PrivacyPage() }
        ctx.router.register("/user-agreement") { baiyan.app.pages.UserAgreementPage() }
        ctx.router.register("/zh/about") { baiyan.app.pages.zh.AboutPage() }
        ctx.router.register("/zh/privacy") { baiyan.app.pages.zh.PrivacyPage() }
        ctx.router.register("/zh/user-agreement") { baiyan.app.pages.zh.UserAgreementPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.DISALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("headline-text", baiyan.app.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text", baiyan.app.SubheadlineTextStyle)
        ctx.theme.registerStyle("markdown", baiyan.app.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("page-content", baiyan.app.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("footer", baiyan.app.components.sections.FooterStyle)
        ctx.theme.registerStyle("nav-header", baiyan.app.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("hero-container", baiyan.app.pages.HeroContainerStyle)
        ctx.theme.registerStyle("home-grid", baiyan.app.pages.HomeGridStyle)
        ctx.theme.registerStyle("home-grid-cell", baiyan.app.pages.HomeGridCellStyle)
        ctx.theme.registerStyle("center-image", baiyan.app.pages.CenterImageStyle)
        ctx.theme.registerStyle("center-app-text-size", baiyan.app.pages.CenterAppTextSize)
        ctx.theme.registerVariant("-circle", baiyan.app.CircleButtonVariant)
        ctx.theme.registerVariant("-uncolored", baiyan.app.UncoloredButtonVariant)
        ctx.theme.registerKeyframes("side-menu-slide-in",
                baiyan.app.components.sections.SideMenuSlideInAnim)
        baiyan.app.initColorMode(ctx)
        baiyan.app.initSiteStyles(ctx)
        baiyan.app.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Baiyan"))
    renderComposable(rootElementId = "root") {
        baiyan.app.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
