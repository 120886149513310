package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsZh : Strings {
  override val locale: String = "zh"

  override val app_name: String = "百言"

  override val home: String = "首页"

  override val about: String = "关于"

  override val privacy: String = "隐私"

  override val user_agreement: String = "用户协议"

  override val terms_of_service: String = "服务条款"

  override val privacy_policy: String = "隐私政策"
}
