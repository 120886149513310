package baiyan.app.pages.fr

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun UserAgreementPage() {
    baiyan.app.components.layouts.MarkdownLayout("UserAgreement") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("accord-d-utilisateur-pour-baiyan") }) {
            org.jetbrains.compose.web.dom.Text("Accord d'Utilisateur pour Baiyan")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Cet Accord d'Utilisateur détaille les termes et conditions sous lesquels vous pouvez utiliser l'application Baiyan, une plateforme alimentée par l'IA conçue pour rendre l'apprentissage des langues aussi captivant que naviguer sur TikTok.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("acceptation-des-termes") }) {
            org.jetbrains.compose.web.dom.Text("Acceptation des Termes")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("En accédant ou en utilisant l'application Baiyan, vous acceptez d'être lié par les termes de cet Accord d'Utilisateur, les lois et règlements applicables, ainsi que toute autre politique applicable. Si vous n'êtes pas d'accord avec une partie des termes, vous devez cesser immédiatement l'utilisation de nos services.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("enregistrement-et-compte") }) {
            org.jetbrains.compose.web.dom.Text("Enregistrement et Compte")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Pour accéder à certaines fonctionnalités de l'application, vous devrez peut-être vous enregistrer et créer un compte. En créant un compte, vous acceptez de :")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Fournir des informations exactes et actuelles sur vous-même.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Maintenir la confidentialité de votre compte et de votre mot de passe.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Accepter la responsabilité de toutes les activités qui se produisent sous votre compte ou mot de passe.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("licence-d-utilisation") }) {
            org.jetbrains.compose.web.dom.Text("Licence d'Utilisation")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan vous accorde une licence révocable, non exclusive et non transférable d'utiliser l'application uniquement à des fins personnelles et non commerciales. Cette licence n'inclut pas la revente ou l'utilisation commerciale de l'application ou de son contenu ; toute collecte et utilisation de listes de produits, descriptions ou prix ; toute utilisation dérivée de l'application ou de son contenu ; ou toute utilisation de l'exploration de données, robots ou outils similaires de collecte et d'extraction de données.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("restrictions-d-utilisation") }) {
            org.jetbrains.compose.web.dom.Text("Restrictions d'Utilisation")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Il vous est interdit d'utiliser l'application pour :")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Conduire ou promouvoir des activités illégales.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Porter atteinte aux droits de propriété intellectuelle de l'application ou d'autrui.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Transmettre du contenu qui est intimidant, diffamatoire, abusif ou autrement répréhensible.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Télécharger ou transmettre des virus ou tout autre type de code malveillant qui pourrait affecter la fonctionnalité ou l'opération de l'application.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("modifications-de-l-application") }) {
            org.jetbrains.compose.web.dom.Text("Modifications de l'Application")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan se réserve le droit à tout moment de modifier ou de discontinuer, temporairement ou de manière permanente, l'application (ou une partie de celle-ci) avec ou sans préavis. Vous acceptez que Baiyan ne soit pas tenu responsable envers vous ou tout tiers pour toute modification, suspension ou interruption du service.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("résiliation") }) {
            org.jetbrains.compose.web.dom.Text("Résiliation")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan peut résilier ou suspendre votre accès à tout ou partie de l'application immédiatement, sans préavis ni responsabilité, pour quelque raison que ce soit, y compris sans limitation si vous violez cet Accord d'Utilisateur. À la résiliation, votre droit d'utiliser l'application cessera immédiatement.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("exclusion-de-garanties") }) {
            org.jetbrains.compose.web.dom.Text("Exclusion de Garanties")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("L'application est fournie sur une base \"TELLE QUELLE\" et \"SELON LA DISPONIBILITÉ\". Baiyan ne fait aucune déclaration ni garantie de quelque nature que ce soit, explicite ou implicite, quant au fonctionnement de l'application ou aux informations, contenus, matériaux ou produits inclus sur l'application.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("limitation-de-responsabilité") }) {
            org.jetbrains.compose.web.dom.Text("Limitation de Responsabilité")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("En aucun cas Baiyan ou ses directeurs, employés ou agents ne seront tenus responsables de dommages indirects, incidentels, punitifs ou consécutifs de quelque nature que ce soit liés à votre utilisation de l'application.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("loi-applicable") }) {
            org.jetbrains.compose.web.dom.Text("Loi Applicable")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Cet Accord d'Utilisateur sera régi et interprété conformément aux lois de la juridiction où Baiyan est basé, sans donner effet à aucun principe de conflits de lois.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("modifications-de-cet-accord") }) {
            org.jetbrains.compose.web.dom.Text("Modifications de cet Accord")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan se réserve le droit, à sa seule discrétion, de mettre à jour, de modifier ou de remplacer toute partie de cet Accord d'Utilisateur en publiant des mises à jour et des changements sur l'application. Il est de votre responsabilité de vérifier périodiquement les changements dans l'application.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("informations-de-contact") }) {
            org.jetbrains.compose.web.dom.Text("Informations de Contact")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Les questions concernant l'Accord d'Utilisateur doivent nous être envoyées à [")
            com.varabyte.kobweb.silk.components.navigation.Link("mailto:support@baiyan.app") {
                org.jetbrains.compose.web.dom.Text("support@baiyan.app")
            }
            org.jetbrains.compose.web.dom.Text("].")
        }
    }
}
