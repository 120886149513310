package baiyan.app.pages.ja

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun UserAgreementPage() {
    baiyan.app.components.layouts.MarkdownLayout("UserAgreement") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("百言ユーザー契約") }) {
            org.jetbrains.compose.web.dom.Text("百言ユーザー契約")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("このユーザー契約は、百言アプリ（TikTokを閲覧するほど魅力的な言語学習を目指して設計されたAI駆動のプラットフォーム）を使用する条件を概説しています。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("条件の承認") }) {
            org.jetbrains.compose.web.dom.Text("条件の承認")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言アプリにアクセスまたは使用することにより、このユーザー契約の条件、適用される法律や規則、及びその他の適用されるポリシーに拘束されることに同意することになります。条件のいずれかに同意しない場合は、直ちに当社のサービスの使用を中止する必要があります。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("登録とアカウント") }) {
            org.jetbrains.compose.web.dom.Text("登録とアカウント")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("アプリの一部の機能にアクセスするためには、登録してアカウントを作成する必要があります。アカウントを作成することにより、以下に同意することになります：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("自分自身に関する正確で最新の情報を提供する。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("アカウントとパスワードの機密性を維持する。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("アカウントまたはパスワードの下で発生するすべての活動に対して責任を受け入れる。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("使用ライセンス") }) {
            org.jetbrains.compose.web.dom.Text("使用ライセンス")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言は、個人的で非商業的目的のためにのみアプリを使用するための非排他的で譲渡不可能な、取り消し可能なライセンスを付与します。このライセンスには、アプリまたはその内容の再販や商業利用、商品リスト、説明、または価格の収集および使用、アプリまたはその内容の派生的な使用、データマイニング、ロボット、または類似のデータ収集および抽出ツールの使用は含まれません。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("使用上の制限") }) {
            org.jetbrains.compose.web.dom.Text("使用上の制限")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("アプリを使用して以下の行為を行うことは禁止されています：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("違法行為の実施または促進。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("アプリまたは他者の知的財産権の侵害。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("いじめ、中傷、虐待的、またはその他の反感を催す内容の送信。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("アプリの機能または運営に影響を与える可能性のあるウイルスまたはその他の種類の悪意のあるコードのアップロードまたは送信。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("アプリの変更") }) {
            org.jetbrains.compose.web.dom.Text("アプリの変更")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言は、予告なしにいつでもアプリ（またはその一部）を変更または一時的もしくは永久に中止する権利を留保します。サービスの変更、停止、中止について、百言があなたまたは第三者に対して責任を負わないことに同意します。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("契約の終了") }) {
            org.jetbrains.compose.web.dom.Text("契約の終了")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言は、ユーザー契約の違反を含む任意の理由により、事前の通知または責任なしに、直ちにあなたがアプリの全部または一部へのアクセスを終了または停止することができます。契約終了時には、アプリを使用する権利が直ちに停止します。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("保証の否認") }) {
            org.jetbrains.compose.web.dom.Text("保証の否認")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("アプリは「現")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("状有姿」および「利用可能な状態」で提供されます。百言は、アプリの運用やアプリに含まれる情報、コンテンツ、素材、製品に関して、明示的または暗黙的ないかなる保証も表明しません。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("責任の制限") }) {
            org.jetbrains.compose.web.dom.Text("責任の制限")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("いかなる場合においても、百言またはその役員、従業員、代理人は、アプリの使用に関連して発生した間接的、偶発的、懲罰的、または結果的損害について責任を負いません。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("準拠法") }) {
            org.jetbrains.compose.web.dom.Text("準拠法")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("このユーザー契約は、百言の所在地の法律に従って解釈され、準拠されます。法の衝突の原則は適用されません。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("この契約の変更") }) {
            org.jetbrains.compose.web.dom.Text("この契約の変更")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言は、独自の裁量により、アプリに更新や変更を掲載することにより、このユーザー契約のいかなる部分でも更新、変更、または置換する権利を留保します。変更を定期的にアプリで確認する責任はユーザーにあります。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("連絡先情報") }) {
            org.jetbrains.compose.web.dom.Text("連絡先情報")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("ユーザー契約に関する質問は、[")
            com.varabyte.kobweb.silk.components.navigation.Link("mailto:support@baiyan.app") {
                org.jetbrains.compose.web.dom.Text("support@baiyan.app")
            }
            org.jetbrains.compose.web.dom.Text("]までお送りください。")
        }
    }
}
