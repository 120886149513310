package baiyan.app.pages.hant

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun UserAgreementPage() {
    baiyan.app.components.layouts.MarkdownLayout("UserAgreement") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("百言用戶協議") }) {
            org.jetbrains.compose.web.dom.Text("百言用戶協議")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("本用戶協議概述了您使用百言應用程式的條款與條件，此為一個以AI驅動的平台，旨在使語言學習與在TikTok上瀏覽一樣引人入勝。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("接受條款") }) {
            org.jetbrains.compose.web.dom.Text("接受條款")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("通過訪問或使用百言應用程式，您同意受本用戶協議、適用法律和規章以及任何其他適用政策的約束。如果您不同意條款的任何部分，您必須立即停止使用我們的服務。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("註冊和帳戶") }) {
            org.jetbrains.compose.web.dom.Text("註冊和帳戶")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("為了使用應用的某些功能，您可能需要註冊並創建帳戶。通過創建帳戶，您同意：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("提供有關您自己的準確和最新的資訊。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("維護您帳戶和密碼的機密性。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("接受負責任何在您的帳戶或密碼下發生的所有活動。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("使用許可") }) {
            org.jetbrains.compose.web.dom.Text("使用許可")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言授予您一個非獨家、不可轉讓、可撤銷的許可，僅供您個人、非商業目的使用應用程式。此許可不包括應用程式或其內容的任何轉售或商業使用；任何產品列表、描述或價格的收集和使用；應用程式或其內容的任何衍生使用；或任何使用數據挖掘、機器人或類似數據收集和提取工具的使用。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("使用限制") }) {
            org.jetbrains.compose.web.dom.Text("使用限制")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("您被禁止使用應用程式來：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("從事或促進任何非法活動。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("侵犯應用程式或其他人的知識產權。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("傳輸欺凌、誹謗、辱駡或其他令人反感的內容。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("上傳或傳輸病毒或任何其他類型的惡意代碼，這些代碼將或可能以任何方式影響應用程式的功能或運作。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("應用修改") }) {
            org.jetbrains.compose.web.dom.Text("應用修改")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言保留隨時修改或永久性或臨時性中止應用程式（或其任何部分）的權利，無論是否通知。您同意百言對您或任何第三方因任何修改、暫停或中止服務而造成的損失不承擔責任。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("終止") }) {
            org.jetbrains.compose.web.dom.Text("終止")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言可以立即終止或暫停您對應用的全部或部分訪問權限，無需事先通知或責任，原因包括但不限於您違反用戶協議。終止後，您使用應用程式的權利將立即終止。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("免責聲明") }) {
            org.jetbrains.compose.web.dom.Text("免責聲明")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("應用程式是在“現狀”和“可用”基礎上提供的。百言對於應用程式的運作或應用程式上包含的資訊、內容、材料或產品不做任何形式的明示或暗示的聲明或保證。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("責任限制") }) {
            org.jetbrains.compose.web.dom.Text("責任限制")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("在任何情")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("況下，百言或其董事、員工或代理人均不對任何間接、偶然、懲罰性或相關的您使用應用程式的後果性損害承擔責任。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("管轄法律") }) {
            org.jetbrains.compose.web.dom.Text("管轄法律")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("本用戶協議應受百言所在地法律的管轄並依據該法律解釋，不考慮法律衝突的原則。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("協議變更") }) {
            org.jetbrains.compose.web.dom.Text("協議變更")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言保留單方面更新、更改或替換本用戶協議任何部分的權利，並將更新和更改發布於應用程式上。檢查應用程式以瞭解變更是您的責任。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("聯絡資訊") }) {
            org.jetbrains.compose.web.dom.Text("聯絡資訊")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("關於用戶協議的問題應發送至我們的[")
            com.varabyte.kobweb.silk.components.navigation.Link("mailto:support@baiyan.app") {
                org.jetbrains.compose.web.dom.Text("support@baiyan.app")
            }
            org.jetbrains.compose.web.dom.Text("]。")
        }
    }
}
