package baiyan.app.bridge

import baiyan.app.APP_LOCALE_KEY
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlinx.browser.window


@JsExport
@OptIn(ExperimentalJsExport::class)
object KmpJsBridge {

    fun init() {
        // 获取 window.kmpJsBridge 对象
        val bridge = window.asDynamic().kmpJsBridge

        // 如果桥接对象未定义，则定义一个空对象
        if (bridge == undefined) {
            window.asDynamic().kmpJsBridge = js("{}")
        }

        // 重新获取桥接对象
        val updatedBridge = window.asDynamic().kmpJsBridge

        // 将 onCallback 方法挂载到桥接对象上
        updatedBridge.setLocal = { callbackId: String, locale: String ->
            setLocal(callbackId, locale)
        }

        console.log("LocaleBridge initialized and setLocal attached to kmpJsBridge")
    }

    fun setLocal(callbackId: String, locale: String) {
        console.log("LocaleBridge.setLocal called with locale: $locale")

        // 更新 localStorage 中的语言设置
        window.localStorage.setItem(APP_LOCALE_KEY, locale)

        // 获取当前的语言代码
        val currentLocale = getCurrentLanguageCode()

        // 更新 URL 中的语言部分
        updatePathLanguage(currentLocale, locale)

        // 重新加载页面以应用更改
        window.location.reload()

        // 通过桥接对象发送回调
        window.asDynamic().kmpJsBridge.onCallback(callbackId, "Locale set to $locale")
    }

    private fun updatePathLanguage(currentLocale: String, newLocale: String) {
        val newPath = window.location.pathname.replaceFirst("/$currentLocale/", "/$newLocale/")
        window.history.pushState(null, "", newPath)
    }
}