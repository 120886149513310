package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsFr : Strings {
  override val locale: String = "fr"

  override val app_name: String = "Baiyan"

  override val home: String = "Accueil"

  override val about: String = "À propos"

  override val privacy: String = "Confidentialité"

  override val user_agreement: String = "Accord utilisateur"

  override val terms_of_service: String = "Conditions d'utilisation"

  override val privacy_policy: String = "Politique de confidentialité"
}
