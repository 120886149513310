package baiyan.app.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    baiyan.app.components.layouts.MarkdownLayout("About") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("about-baiyan") }) {
            org.jetbrains.compose.web.dom.Text("About Baiyan")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan is a cutting-edge AI-powered application that transforms the way users learn languages through video content, making it as engaging and addictive as scrolling through TikTok.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("learn") }) {
            org.jetbrains.compose.web.dom.Text("Learn")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan leverages short, engaging videos to teach users authentic pronunciations in various languages, creating an immersive learning experience that is both effective and enjoyable.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("features") }) {
            org.jetbrains.compose.web.dom.Text("Features")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ai-generated-subtitles") }) {
            org.jetbrains.compose.web.dom.Text("AI-Generated Subtitles")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan incorporates advanced AI technology to automatically generate accurate subtitles for each word in the videos. This feature enhances understanding and retention by allowing users to see the spelling and meaning of words as they are spoken.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("multi-language-support") }) {
            org.jetbrains.compose.web.dom.Text("Multi-Language Support")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("The platform supports a wide range of languages, providing users with the opportunity to learn multiple languages in one intuitive environment.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("user-friendly-interface") }) {
            org.jetbrains.compose.web.dom.Text("User-Friendly Interface")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Designed with simplicity in mind, Baiyan offers a user-friendly interface that makes language learning accessible to everyone. Users can easily navigate through different videos and track their progress.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("engagement-tools") }) {
            org.jetbrains.compose.web.dom.Text("Engagement Tools")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan includes various tools to keep users motivated, such as progress trackers, interactive quizzes, and daily challenges that encourage regular practice.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("technology") }) {
            org.jetbrains.compose.web.dom.Text("Technology")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan utilizes the latest AI technologies to analyze and break down language from natural, everyday interactions captured in video format, offering users a unique and effective learning tool.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("starting-point") }) {
            org.jetbrains.compose.web.dom.Text("Starting Point")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan is more than just a language learning app; it's a platform that users can customize and adapt to their learning style. Whether starting from scratch or looking to enhance existing language skills, Baiyan provides a robust foundation for all learners.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("export-and-engage") }) {
            org.jetbrains.compose.web.dom.Text("Export and Engage")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("As users progress, they can share their achievements and interact with a global community of learners. Baiyan not only supports individual growth but also fosters a collaborative environment where users can exchange tips, insights, and encouragement.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Engage with Baiyan today and transform your language learning experience into an enjoyable and fulfilling journey!")
        }
    }
}
