package baiyan.app.pages.ko

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun UserAgreementPage() {
    baiyan.app.components.layouts.MarkdownLayout("UserAgreement") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("바이옌-사용자-계약") }) {
            org.jetbrains.compose.web.dom.Text("바이옌 사용자 계약")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("이 사용자 계약은 TikTok을 탐색하는 것처럼 매력적인 언어 학습을 목표로 설계된 AI 기반 플랫폼인 바이옌 앱을 사용할 수 있는 조건과 규정을 개요합니다.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("약관의-수락") }) {
            org.jetbrains.compose.web.dom.Text("약관의 수락")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌 앱에 접근하거나 사용함으로써, 귀하는 이 사용자 계약의 약관, 적용 가능한 법률 및 규정, 그리고 기타 관련 정책에 구속됨에 동의합니다. 약관의 일부에 동의하지 않는 경우, 즉시 저희 서비스 사용을 중단해야 합니다.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("등록-및-계정") }) {
            org.jetbrains.compose.web.dom.Text("등록 및 계정")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("앱의 일부 기능에 접근하려면 등록하고 계정을 생성해야 할 수 있습니다. 계정을 생성함으로써 귀하는 다음에 동의합니다:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("자신에 대한 정확하고 최신 정보를 제공합니다.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("계정 및 비밀번호의 기밀성을 유지합니다.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("귀하의 계정이나 비밀번호 아래에서 발생하는 모든 활동에 대한 책임을 수락합니다.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("사용-라이선스") }) {
            org.jetbrains.compose.web.dom.Text("사용 라이선스")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 귀하에게 개인적이고 비상업적인 목적으로만 앱을 사용할 수 있는 비독점적이고 양도 불가능하며 철회 가능한 라이선스를 부여합니다. 이 라이선스는 앱이나 그 내용의 어떠한 재판매나 상업적 사용, 제품 목록, 설명 또는 가격의 수집 및 사용, 앱이나 그 내용의 파생적 사용, 데이터 마이닝, 로봇 또는 유사한 데이터 수집 및 추출 도구의 사용을 포함하지 않습니다.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("사용-제한") }) {
            org.jetbrains.compose.web.dom.Text("사용 제한")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("귀하는 앱을 사용하여 다음과 같은 행위를 하는 것이 금지됩니다:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("불법 활동을 수행하거나 촉진합니다.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("앱이나 타인의 지적 재산권을 침해합니다.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("괴롭힘, 명예 훼손, 학대적이거나 그 외에 불쾌한 내용을 전송합니다.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("앱의 기능이나 작동에 영향을 줄 수 있는 바이러스나 기타 유형의 악의적인 코드를 업로드하거나 전송합니다.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("앱의-변경") }) {
            org.jetbrains.compose.web.dom.Text("앱의 변경")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 언제든지 사전 통지나 책임 없이 일시적 또는 영구적으로 앱(또는 그 일부)를 수정하거나 중단할 권리를 보유합니다. 서비스의 수정, 중단 또는 폐지로 인해 발생하는 어떠한 책임도 바이옌이 귀하나 제삼자에게 부담하지 않습니다.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("계약-종료") }) {
            org.jetbrains.compose.web.dom.Text("계약 종료")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 사용자 계약을 위반하는 경우를 포함하여 어떠한 이유로든 사전 통지나 책임 없이 즉시 귀하의 앱 전체 또는 일부에 대한 접근을 종료하거나 중단할 수 있습니다. 계약 종료 시, 귀하의 앱 사용 권리는 즉시 중단됩니다.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("보증의-부인") }) {
            org.jetbrains.compose.web.dom.Text("보증의 부인")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("앱은 \"있는 그대로\" 및 \"사용 가능한")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("상태로\" 제공됩니다. 바이옌은 앱의 운영이나 앱에 포함된 정보, 콘텐츠, 재료 또는 제품에 대해 어떠한 종류의 명시적 또는 묵시적인 표현이나 보증도 하지 않습니다.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("책임의-제한") }) {
            org.jetbrains.compose.web.dom.Text("책임의 제한")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("어떠한 경우에도 바이옌 또는 그 이사, 직원, 대리인은 귀하의 앱 사용과 관련하여 발생하는 어떠한 간접적, 우발적, 징벌적 또는 결과적 손해에 대해 책임을 지지 않습니다.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("준거법") }) {
            org.jetbrains.compose.web.dom.Text("준거법")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("이 사용자 계약은 바이옌이 위치한 관할 구역의 법률에 따라 해석되고 준수됩니다. 법의 충돌 원칙은 적용되지 않습니다.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("이-계약의-변경") }) {
            org.jetbrains.compose.web.dom.Text("이 계약의 변경")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 앱에 업데이트 및 변경 사항을 게시함으로써 이 사용자 계약의 일부를 임의로 업데이트, 변경 또는 대체할 권리를 보유합니다. 변경 사항을 주기적으로 확인하는 것은 귀하의 책임입니다.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("연락처-정보") }) {
            org.jetbrains.compose.web.dom.Text("연락처 정보")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("사용자 계약에 대한 질문은 [")
            com.varabyte.kobweb.silk.components.navigation.Link("mailto:support@baiyan.app") {
                org.jetbrains.compose.web.dom.Text("support@baiyan.app")
            }
            org.jetbrains.compose.web.dom.Text("]로 보내주십시오.")
        }
    }
}
