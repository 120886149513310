package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsKo : Strings {
  override val locale: String = "ko"

  override val app_name: String = "Baiyan"

  override val home: String = "홈"

  override val about: String = "소개"

  override val privacy: String = "개인정보 보호"

  override val user_agreement: String = "사용자 약관"

  override val terms_of_service: String = "서비스 약관"

  override val privacy_policy: String = "개인정보 보호정책"
}
