package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsJa : Strings {
  override val locale: String = "ja"

  override val app_name: String = "Baiyan"

  override val home: String = "ホーム"

  override val about: String = "紹介"

  override val privacy: String = "プライバシー"

  override val user_agreement: String = "利用規約"

  override val terms_of_service: String = "利用規約"

  override val privacy_policy: String = "プライバシーポリシー"
}
