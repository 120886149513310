package baiyan.app.pages.en

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun PrivacyPage() {
    baiyan.app.components.layouts.MarkdownLayout("Privacy") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("privacy-policy-for-baiyan") }) {
            org.jetbrains.compose.web.dom.Text("Privacy Policy for Baiyan")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("This Privacy Policy explains how information about you is collected, used, and disclosed by Baiyan when you use our mobile application and related services.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("1-information-collection") }) {
            org.jetbrains.compose.web.dom.Text("1. Information Collection")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("When you use the Baiyan app, we collect various types of information from and about you:")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("personal-information") }) {
            org.jetbrains.compose.web.dom.Text("Personal Information")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Registration and Profile Information:")
                }
                org.jetbrains.compose.web.dom.Text(" When you create an account, update your profile, or set preferences, we collect personal information such as your name, email address, and language preferences.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("usage-information") }) {
            org.jetbrains.compose.web.dom.Text("Usage Information")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Interactions with the App:")
                }
                org.jetbrains.compose.web.dom.Text(" We collect information about how you interact with the app, including the pages you view, the videos you watch, and other actions within the app.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("technical-and-device-information") }) {
            org.jetbrains.compose.web.dom.Text("Technical and Device Information")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Device Information:")
                }
                org.jetbrains.compose.web.dom.Text(" We collect information about the mobile device you use to access our app, including hardware models, operating system versions, and mobile network information.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("cookies-and-tracking-technologies") }) {
            org.jetbrains.compose.web.dom.Text("Cookies and Tracking Technologies")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("We use cookies and other tracking technologies to collect information about your usage and preferences. You can set your device to refuse cookies, but some parts of our app may then not function properly.")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("2-use-of-information") }) {
            org.jetbrains.compose.web.dom.Text("2. Use of Information")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("The information we collect is used in various ways, including to:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Provide, maintain, and improve the Baiyan app;")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Understand and analyze how you use our app and develop new products, services, and features;")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the app, and for marketing and promotional purposes;")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Send you notifications;")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("3-sharing-of-information") }) {
            org.jetbrains.compose.web.dom.Text("3. Sharing of Information")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("We may share the information we collect in various ways, including the following:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Service Providers:")
                }
                org.jetbrains.compose.web.dom.Text(" We may share information with third-party vendors and service providers that provide services on our behalf, such as helping to provide our Services or providing analytic services.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Legal Requirements:")
                }
                org.jetbrains.compose.web.dom.Text(" We may disclose information if required to do so by law or in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend our rights or property, protect the personal safety of users of the app, or protect against legal liability.")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("4-security") }) {
            org.jetbrains.compose.web.dom.Text("4. Security")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("We take reasonable measures to help protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("5-international-transfers") }) {
            org.jetbrains.compose.web.dom.Text("5. International Transfers")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan operates on a global scale, and we may process, store, and transfer the information we collect about you in and to various countries around the world, including outside of your own country. Here's how we handle these transfers:")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("transfer-mechanisms") }) {
            org.jetbrains.compose.web.dom.Text("Transfer Mechanisms")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Legal Compliance:")
                }
                org.jetbrains.compose.web.dom.Text(" We transfer personal information in compliance with applicable data protection laws. We utilize standard contractual clauses approved by the European Commission, consent from individuals (where required), and other legally accepted mechanisms to ensure lawful transfer of personal data.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("data-protection") }) {
            org.jetbrains.compose.web.dom.Text("Data Protection")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Safeguards:")
                }
                org.jetbrains.compose.web.dom.Text(" To protect your personal information, we apply strict safeguards, including encryption and the use of secure server facilities. We ensure that any third party processing your information equally commits to implement and maintain these safeguards.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("user-rights") }) {
            org.jetbrains.compose.web.dom.Text("User Rights")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Access and Control:")
                }
                org.jetbrains.compose.web.dom.Text(" Regardless of where your information is processed, you retain the right to access, amend, or delete your personal data. For requests or concerns, please contact us directly using the information provided in our Contact Information section.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("regulatory-oversight") }) {
            org.jetbrains.compose.web.dom.Text("Regulatory Oversight")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Compliance with Local Laws:")
                }
                org.jetbrains.compose.web.dom.Text(" We regularly review our data transfer practices to ensure they align with local legal requirements and cooperate with local data protection authorities as required. This includes adhering to any specific laws and regulations that govern data transfers in the regions where we operate.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("commitment-to-privacy") }) {
            org.jetbrains.compose.web.dom.Text("Commitment to Privacy")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Privacy Shield Framework:")
                }
                org.jetbrains.compose.web.dom.Text(" For transfers of data from the EU and Switzerland to the U.S., we comply with the Privacy Shield Framework, ensuring that we maintain a level of data protection consistent with established EU data protection requirements.")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("This commitment to adhering to local and international data protection laws ensures that your information is handled respectfully and responsibly, no matter where it is processed.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
    }
}
