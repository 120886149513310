package baiyan.app.pages.hant

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    baiyan.app.components.layouts.MarkdownLayout("About") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("關於百言") }) {
            org.jetbrains.compose.web.dom.Text("關於百言")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言是一款尖端的AI驅動應用程序，透過視頻內容轉變了用戶學習語言的方式，使學習變得既引人入勝又上癮，就像在TikTok上滑動一樣。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("學習") }) {
            org.jetbrains.compose.web.dom.Text("學習")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言利用短小精悍的視頻教導用戶各種語言的真實發音，創造出既有效又愉悅的沉浸式學習體驗。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("特色") }) {
            org.jetbrains.compose.web.dom.Text("特色")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ai生成字幕") }) {
            org.jetbrains.compose.web.dom.Text("AI生成字幕")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言結合先進的AI技術，自動為視頻中的每個詞生成準確字幕。此功能通過允許用戶在詞語發音時看到拼寫和含義，增強了理解和記憶。")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("多語言支持") }) {
            org.jetbrains.compose.web.dom.Text("多語言支持")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("平台支持多種語言，為用戶提供在一個直觀環境中學習多種語言的機會。")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("用戶友好界面") }) {
            org.jetbrains.compose.web.dom.Text("用戶友好界面")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言的設計以簡潔為主，提供了一個用戶友好的界面，使語言學習對所有人都易於接觸。用戶可以輕鬆地瀏覽不同的視頻並追踪他們的進度。")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("參與工具") }) {
            org.jetbrains.compose.web.dom.Text("參與工具")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言包括各種工具以保持用戶的動力，例如進度追踪器、互動測驗和每日挑戰，鼓勵定期練習。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("技術") }) {
            org.jetbrains.compose.web.dom.Text("技術")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言利用最新的AI技術分析並拆解在視頻格式中捕捉到的自然日常互動中的語言，為用戶提供了一個獨特且有效的學習工具。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("起點") }) {
            org.jetbrains.compose.web.dom.Text("起點")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言不僅僅是一個語言學習應用程序；它是一個用戶可以自定義並適應其學習風格的平台。無論是從零開始，還是希望提高現有的語言技能，百言都為所有學習者提供了堅實的基礎。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("輸出和參與") }) {
            org.jetbrains.compose.web.dom.Text("輸出和參與")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("隨著用戶的進步，他們可以分享他們的成就並與全球學習者社區互動。百言不僅支持個人成長，還培養了一個協作環境，用戶可以在其中交流技巧、見解和鼓勵。")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("今天就開始與百言互動，將您的語言學習體驗轉變為愉快且充實的旅程！")
        }
    }
}
