package baiyan.app.pages.hant

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun PrivacyPage() {
    baiyan.app.components.layouts.MarkdownLayout("Privacy") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("百言隱私政策") }) {
            org.jetbrains.compose.web.dom.Text("百言隱私政策")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("本隱私政策解釋百言在您使用我們的移動應用及相關服務時，如何收集、使用及披露您的資訊。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("1-資訊收集") }) {
            org.jetbrains.compose.web.dom.Text("1. 資訊收集")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("當您使用百言應用時，我們從您那裡收集各種類型的資訊：")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("個人資訊") }) {
            org.jetbrains.compose.web.dom.Text("個人資訊")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**註冊及個人資料訊息：**當您創建帳戶、更新您的個人檔案或設定偏好設定時，我們收集如您的姓名、電子郵件地址及語言偏好等個人資訊。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("使用資訊") }) {
            org.jetbrains.compose.web.dom.Text("使用資訊")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**與應用的互動：**我們收集您與應用互動的資訊，包括您查看的頁面、觀看的視頻以及應用內的其他操作。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("技術和設備資訊") }) {
            org.jetbrains.compose.web.dom.Text("技術和設備資訊")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**設備資訊：**我們收集有關您用來訪問我們應用的移動設備的資訊，包括硬體型號、作業系統版本和移動網絡資訊。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("cookie和追蹤技術") }) {
            org.jetbrains.compose.web.dom.Text("Cookie和追蹤技術")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("我們使用Cookie和其他追蹤技術來收集有關您的使用和偏好的資訊。您可以設定您的設備拒絕Cookie，但這樣可能導致我們的應用的某些部分無法正常工作。")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("2-資訊使用") }) {
            org.jetbrains.compose.web.dom.Text("2. 資訊使用")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("我們以各種方式使用收集的資訊，包括：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("提供、維護和改進百言應用；")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("理解和分析您如何使用我們的應用，並開發新產品、服務和功能；")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("與您溝通，無論是直接還是透過我們的合作夥伴，包括提供客服，為您提供應用相關的更新和其他資訊，以及進行市場營銷和推廣活動；")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("向您發送通知；")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("3-資訊分享") }) {
            org.jetbrains.compose.web.dom.Text("3. 資訊分享")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("我們可能以各種方式分享我們收集的資訊，包括以下方式：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**服務提供者：**我們可能與第三方供應商和服務提供者分享資訊，這些供應商和服務提供者代表我們提供服務，例如幫助提供我們的服務或提供分析服務。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**法律要求：**如果法律要求，我們可能會披露資訊，或出於善意信念認為此類行為是為了遵守法律義務，保護和捍衛我們的權利或財產，保護應用用戶的人身安全，或防止法律責任。")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("4-安全") }) {
            org.jetbrains.compose.web.dom.Text("4. 安全")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("我們採取合理措施幫助保護您的資訊免受丟失、盜竊、濫用、未經授權訪問、披露、更改和破壞。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("5-國際傳輸") }) {
            org.jetbrains.compose.web.dom.Text("5. 國際傳輸")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言在全球範圍內運營，我們可能會在世界各地的不同國家處理、存儲和傳輸我們收集的有關您的資訊，包括在您自己的國家之外。以下是我們處理這些轉移的方式：")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("轉移機制") }) {
            org.jetbrains.compose.web.dom.Text("轉移機制")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**法律合規：**我們依據適用的數據保護法律轉移個人資訊。我們利用歐盟委員會批准的標準合約條款，個人的同意（在需要時），以及其他法律接受的機制來確保個人數據的合法轉移。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("數據保護") }) {
            org.jetbrains.compose.web.dom.Text("數據保護")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**保護措施：**為保護您的個人資訊，我們採用嚴格的保護措施，包括加密和使用安全服務器設施。我們確保任何處理您資訊的第三方同樣承諾實施和維持這些保護措施。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("用戶權利") }) {
            org.jetbrains.compose.web.dom.Text("用戶權利")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**訪問和控制：**無論您的資訊在哪裡處理，您都保留訪問、修改或刪除您的個人數據的權利。如有請求或疑慮，請直接使用我們在聯絡資訊部分提供的資訊與我們聯繫。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("監管監督") }) {
            org.jetbrains.compose.web.dom.Text("監管監督")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**遵守當地法律：**我們定期審查我們的數據轉移實踐，以確保它們符合當地的法律要求，並根據需要與當地的數據保護機構合作。這包括遵守我們運營地區的數據轉移相關的特定法律和法規。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("對隱私的承諾") }) {
            org.jetbrains.compose.web.dom.Text("對隱私的承諾")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("**隱私盾框架：**對於從歐盟和瑞士向美國的數據轉移，我們遵守隱私盾框架，確保我們維持與歐盟數據保護要求相一致的數據保護水平。")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("这种对本地和国际数据保护法的承诺确保了无论您的信息在哪里处理，都会以尊重和负责任的方式处理。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
    }
}
