package baiyan.app.pages.fr

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun PrivacyPage() {
    baiyan.app.components.layouts.MarkdownLayout("Privacy") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("politique-de-confidentialité-de-baiyan") }) {
            org.jetbrains.compose.web.dom.Text("Politique de Confidentialité de Baiyan")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Cette politique de confidentialité explique comment les informations vous concernant sont collectées, utilisées et divulguées par Baiyan lorsque vous utilisez notre application mobile et les services associés.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("1-collecte-d-informations") }) {
            org.jetbrains.compose.web.dom.Text("1. Collecte d'informations")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Lorsque vous utilisez l'application Baiyan, nous collectons divers types d'informations de votre part et à votre sujet :")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("informations-personnelles") }) {
            org.jetbrains.compose.web.dom.Text("Informations Personnelles")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Informations d'inscription et de profil :")
                }
                org.jetbrains.compose.web.dom.Text(" Lorsque vous créez un compte, mettez à jour votre profil ou définissez des préférences, nous collectons des informations personnelles telles que votre nom, adresse e-mail et préférences linguistiques.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("informations-d-utilisation") }) {
            org.jetbrains.compose.web.dom.Text("Informations d'Utilisation")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Interactions avec l'App :")
                }
                org.jetbrains.compose.web.dom.Text(" Nous collectons des informations sur la façon dont vous interagissez avec l'application, y compris les pages que vous consultez, les vidéos que vous regardez et les autres actions au sein de l'application.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("informations-techniques-et-sur-les-appareils") }) {
            org.jetbrains.compose.web.dom.Text("Informations Techniques et sur les Appareils")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Informations sur l'appareil :")
                }
                org.jetbrains.compose.web.dom.Text(" Nous collectons des informations sur l'appareil mobile que vous utilisez pour accéder à notre application, y compris les modèles de matériel, les versions des systèmes d'exploitation et les informations sur le réseau mobile.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("cookies-et-technologies-de-suivi") }) {
            org.jetbrains.compose.web.dom.Text("Cookies et Technologies de Suivi")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Nous utilisons des cookies et d'autres technologies de suivi pour collecter des informations sur votre utilisation et vos préférences. Vous pouvez configurer votre appareil pour refuser les cookies, mais certaines parties de notre application peuvent alors ne pas fonctionner correctement.")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("2-utilisation-des-informations") }) {
            org.jetbrains.compose.web.dom.Text("2. Utilisation des Informations")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Les informations que nous collectons sont utilisées de diverses manières, y compris pour :")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Fournir, maintenir et améliorer l'application Baiyan ;")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Comprendre et analyser comment vous utilisez notre application et développer de nouveaux produits, services et fonctionnalités ;")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Communiquer avec vous, directement ou par l'intermédiaire de l'un de nos partenaires, y compris pour le service client, pour vous fournir des mises à jour et d'autres informations relatives à l'application, et à des fins de marketing et de promotion ;")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Vous envoyer des notifications ;")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("3-partage-des-informations") }) {
            org.jetbrains.compose.web.dom.Text("3. Partage des Informations")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Nous pouvons partager les informations que nous collectons de diverses manières, notamment les suivantes :")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Prestataires de services :")
                }
                org.jetbrains.compose.web.dom.Text(" Nous pouvons partager des informations avec des fournisseurs tiers et des prestataires de services qui fournissent des services en notre nom, tels que l'aide à la fourniture de nos services ou la fourniture de services d'analyse.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Exigences légales :")
                }
                org.jetbrains.compose.web.dom.Text(" Nous pouvons divulguer des informations si cela est nécessaire pour se conformer à une obligation légale, protéger et défendre nos droits ou propriété, protéger la sécurité personnelle des utilisateurs de l'application, ou nous protéger contre la responsabilité légale.")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("4-sécurité") }) {
            org.jetbrains.compose.web.dom.Text("4. Sécurité")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Nous prenons des mesures raisonnables pour aider à protéger les informations vous concernant contre la perte, le vol, l'abus, l'accès non autorisé, la divulgation, l'altération et la destruction.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("5-transferts-internationaux") }) {
            org.jetbrains.compose.web.dom.Text("5. Transferts Internationaux")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan opère à l'échelle mondiale, et nous pouvons traiter, stocker et transférer les informations que nous collectons vous concernant dans et vers divers pays du monde, y compris en dehors de votre propre pays. Voici comment nous gérons ces transferts :")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("mécanismes-de-transfert") }) {
            org.jetbrains.compose.web.dom.Text("Mécanismes de Transfert")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Conformité légale :")
                }
                org.jetbrains.compose.web.dom.Text(" Nous transférons des informations personnelles conformément aux lois sur la protection des données applicables. Nous utilisons des clauses contractuelles types approuvées par la Commission européenne, le consentement des individus (lorsqu'il est requis), et d'autres mécanismes légalement acceptés pour assurer le transfert légal des données personnelles.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("protection-des-données") }) {
            org.jetbrains.compose.web.dom.Text("Protection des Données")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Sauvegardes :")
                }
                org.jetbrains.compose.web.dom.Text(" Pour protéger vos informations personnelles, nous appliquons des sauvegardes strictes, y compris le chiffrement et l'utilisation d'installations de serveurs sécurisés. Nous nous assurons que tout tiers traitant vos informations s'engage également à mettre en œuvre et à maintenir ces sauvegardes.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("droitsdes-utilisateurs") }) {
            org.jetbrains.compose.web.dom.Text("Droitsdes Utilisateurs")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Accès et Contrôle :")
                }
                org.jetbrains.compose.web.dom.Text(" Peu importe où vos informations sont traitées, vous conservez le droit d'accéder, de modifier ou de supprimer vos données personnelles. Pour les demandes ou préoccupations, veuillez nous contacter directement en utilisant les informations fournies dans notre section Informations de Contact.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("surveillance-réglementaire") }) {
            org.jetbrains.compose.web.dom.Text("Surveillance Réglementaire")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Conformité avec les Lois Locales :")
                }
                org.jetbrains.compose.web.dom.Text(" Nous examinons régulièrement nos pratiques de transfert de données pour nous assurer qu'elles sont conformes aux exigences légales locales et coopérons avec les autorités de protection des données locales selon les besoins. Cela comprend le respect des lois et réglementations spécifiques qui régissent les transferts de données dans les régions où nous opérons.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("engagement-envers-la-confidentialité") }) {
            org.jetbrains.compose.web.dom.Text("Engagement envers la Confidentialité")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Cadre du Bouclier de la Vie Privée :")
                }
                org.jetbrains.compose.web.dom.Text(" Pour les transferts de données de l'UE et de la Suisse vers les États-Unis, nous nous conformons au cadre du Bouclier de la Vie Privée, assurant que nous maintenons un niveau de protection des données conforme aux exigences de protection des données établies par l'UE.")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Cet engagement à adhérer aux lois locales et internationales sur la protection des données garantit que vos informations sont traitées avec respect et responsabilité, peu importe où elles sont traitées.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
    }
}
