package baiyan.app.pages.zh

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    baiyan.app.components.layouts.MarkdownLayout("About") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("关于百言") }) {
            org.jetbrains.compose.web.dom.Text("关于百言")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言是一款尖端的AI驱动应用程序，通过视频内容改变了用户学习语言的方式，使学习过程与在抖音上滑动浏览一样引人入胜。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("学习") }) {
            org.jetbrains.compose.web.dom.Text("学习")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言利用简短、吸引人的视频教授用户各种语言的真实发音，创造了一种既有效又愉快的沉浸式学习体验。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("特点") }) {
            org.jetbrains.compose.web.dom.Text("特点")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ai生成字幕") }) {
            org.jetbrains.compose.web.dom.Text("AI生成字幕")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言融合了先进的AI技术，自动为视频中的每个单词生成准确的字幕。这一功能通过让用户在听到单词的同时看到单词的拼写和含义，增强了理解和记忆。")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("多语言支持") }) {
            org.jetbrains.compose.web.dom.Text("多语言支持")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("该平台支持广泛的语言，为用户提供在一个直观的环境中学习多种语言的机会。")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("用户友好界面") }) {
            org.jetbrains.compose.web.dom.Text("用户友好界面")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言设计简洁，提供了一个用户友好的界面，使语言学习对每个人都容易接触。用户可以轻松地浏览不同的视频并跟踪他们的进度。")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("互动工具") }) {
            org.jetbrains.compose.web.dom.Text("互动工具")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言包括各种工具以保持用户的动力，如进度跟踪器、互动测验和鼓励定期练习的每日挑战。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("技术") }) {
            org.jetbrains.compose.web.dom.Text("技术")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言利用最新的AI技术分析并解析在视频格式中捕获的自然的日常互动中的语言，为用户提供了一个独特且有效的学习工具。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("起点") }) {
            org.jetbrains.compose.web.dom.Text("起点")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言不仅仅是一个语言学习应用；它是一个平台，用户可以根据自己的学习风格定制和适应。无论是从零开始，还是希望提高现有的语言技能，百言为所有学习者提供了坚实的基础。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("导出与互动") }) {
            org.jetbrains.compose.web.dom.Text("导出与互动")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("随着用户的进步，他们可以分享自己的成就并与全球的学习者社区互动。百言不仅支持个人成长，还培养了一个可以交流技巧、见解和鼓励的协作环境。")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("今天就与百言互动，将您的语言学习体验转变为一段愉快而充实的旅程！")
        }
    }
}
