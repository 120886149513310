package baiyan.app.pages

import Res
import androidx.compose.runtime.Composable
import baiyan.app.components.layouts.PageLayout
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.LineHeight
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle = CssStyle {
    base { Modifier.fillMaxWidth().gap(2.cssRem) }
    Breakpoint.MD { Modifier.margin { top(20.vh) } }
}

// A demo grid that appears on the homepage because it looks good
val HomeGridStyle = CssStyle.base {
    Modifier
        .gap(0.5.cssRem)
        .width(70.cssRem)
        .height(18.cssRem)
}

private val GridCellColorVar by StyleVariable<Color>()
val HomeGridCellStyle = CssStyle.base {
    Modifier
        .backgroundColor(GridCellColorVar.value())
        .boxShadow(blurRadius = 0.6.cssRem, color = GridCellColorVar.value())
        .borderRadius(1.cssRem)
}

@Composable
private fun GridCell(color: Color, row: Int, column: Int, width: Int? = null, height: Int? = null) {
    Div(
        HomeGridCellStyle.toModifier()
            .setVariable(GridCellColorVar, color)
            .gridItem(row, column, width, height)
            .toAttrs()
    )
}

val CenterImageStyle = CssStyle {
    base {
        Modifier.height(15.cssRem)
    }

    Breakpoint.MD {
        Modifier.height(30.cssRem)
    }
}

val CenterAppTextSize = CssStyle {
    base {
        Modifier
            .height(32.px)
            .fontSize(32.px)
    }

    Breakpoint.MD {
        Modifier
            .height(64.px)
            .fontSize(64.px)
    }
}

@Page
@Composable
fun HomePage() {
    PageLayout("Home") {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .align(Alignment.CenterHorizontally)
        ) {
            //val sitePalette = ColorMode.current.toSitePalette()

            Image(
                "/baiyan-icon.svg",
                "Banyan Icon",
                CenterImageStyle.toModifier().fillMaxWidth()
//                    .height(30.cssRem)
                    .display(DisplayStyle.Block)
//                    .displayIfAtLeast(Breakpoint.MD)
            )
            //Spacer()
            SpanText(
                Res.string.app_name,
                CenterAppTextSize.toModifier()
                    .fillMaxWidth()
//                    .height(64.px)
                    .align(Alignment.CenterHorizontally)
                    .opacity(1)
                    .fontFamily("Roboto")
//                    .fontSize(64.px)
                    .fontWeight(FontWeight.Normal)
                    .lineHeight(LineHeight.Normal)
                    .textAlign(TextAlign.Center)
                    .letterSpacing(0.em)
                    .color(Color.rgb(0xFFFFFE))
            )
        }
    }
}
