package baiyan.app.pages.zh

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun PrivacyPage() {
    baiyan.app.components.layouts.MarkdownLayout("Privacy") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("百言隐私政策") }) {
            org.jetbrains.compose.web.dom.Text("百言隐私政策")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("本隐私政策解释了在您使用我们的移动应用程序和相关服务时，百言如何收集、使用和披露您的信息。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("1-信息收集") }) {
            org.jetbrains.compose.web.dom.Text("1. 信息收集")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("当您使用百言应用时，我们会收集您各类信息：")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("个人信息") }) {
            org.jetbrains.compose.web.dom.Text("个人信息")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("注册和个人资料信息：")
                }
                org.jetbrains.compose.web.dom.Text(" 当您创建账户、更新个人资料或设置偏好时，我们会收集您的姓名、电子邮件地址和语言偏好等个人信息。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("使用信息") }) {
            org.jetbrains.compose.web.dom.Text("使用信息")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("与应用的互动：")
                }
                org.jetbrains.compose.web.dom.Text(" 我们收集您与应用的互动信息，包括您查看的页面、观看的视频以及应用内的其他操作。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("技术和设备信息") }) {
            org.jetbrains.compose.web.dom.Text("技术和设备信息")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("设备信息：")
                }
                org.jetbrains.compose.web.dom.Text(" 我们收集您用于访问我们应用的移动设备的信息，包括硬件型号、操作系统版本和移动网络信息。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("cookies-和追踪技术") }) {
            org.jetbrains.compose.web.dom.Text("Cookies 和追踪技术")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("我们使用 cookies 和其他追踪技术来收集您的使用和偏好信息。您可以设置您的设备拒绝 cookies，但这可能会导致我们应用的部分功能无法正常使用。")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("2-信息的使用") }) {
            org.jetbrains.compose.web.dom.Text("2. 信息的使用")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("我们收集的信息用于多种方式，包括：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("提供、维护和改善百言应用；")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("了解和分析您如何使用我们的应用，并开发新的产品、服务和功能；")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("与您沟通，无论是直接还是通过我们的合作伙伴，包括客户服务、向您提供应用更新和其他相关信息，以及用于营销和促销目的；")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("发送通知；")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("3-信息的共享") }) {
            org.jetbrains.compose.web.dom.Text("3. 信息的共享")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("我们可能以多种方式共享我们收集的信息，包括以下内容：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("服务提供商：")
                }
                org.jetbrains.compose.web.dom.Text(" 我们可能与为我们提供服务的第三方供应商和服务提供商共享信息，例如帮助提供我们的服务或提供分析服务。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("法律要求：")
                }
                org.jetbrains.compose.web.dom.Text(" 如果法律要求，或出于善意信念认为这样的行动是必要的，以遵守法律义务，保护和捍卫我们的权利或财产，保护应用用户的个人安全，或防止法律责任，我们可能会披露信息。")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("4-安全") }) {
            org.jetbrains.compose.web.dom.Text("4. 安全")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("我们采取合理措施，帮助保护您的信息免受丢失、盗窃、滥用、未经授权的访问、披露、修改和破坏的风险。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("5-国际转移") }) {
            org.jetbrains.compose.web.dom.Text("5. 国际转移")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言在全球范围内运营，我们可能会在世界各地，包括您所在国家以外的国家，处理、存储和转移我们收集的有关您的信息。以下是我们处理这些转移的方式：")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("转移机制") }) {
            org.jetbrains.compose.web.dom.Text("转移机制")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("法律合规：")
                }
                org.jetbrains.compose.web.dom.Text(" 我们在符合适用的数据保护法律的情况下转移个人信息。我们使用欧盟委员会批准的标准合同条款、个人同意（如有需要）和其他法律认可的机制，以确保个人数据的合法转移。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("数据保护") }) {
            org.jetbrains.compose.web.dom.Text("数据保护")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("安全保障：")
                }
                org.jetbrains.compose.web.dom.Text(" 为保护您的个人信息，我们采取严格的安全保障措施，包括加密和使用安全的服务器设施。我们确保任何处理您信息的第三方同样承诺实施和维护这些安全保障措施。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("用户权利") }) {
            org.jetbrains.compose.web.dom.Text("用户权利")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("访问和控制：")
                }
                org.jetbrains.compose.web.dom.Text(" 无论您的信息在哪里处理，您都保留访问、修改或删除个人数据的权利。对于请求或疑虑，请使用我们在联系方式部分提供的信息直接联系我们。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("监管监督") }) {
            org.jetbrains.compose.web.dom.Text("监管监督")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("遵守当地法律：")
                }
                org.jetbrains.compose.web.dom.Text(" 我们定期审查我们的数据传输实践，确保其符合当地法律要求，并根据需要与当地数据保护当局合作。这包括遵守我们运营地区的有关数据传输的特定法律和法规。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("对隐私的承诺") }) {
            org.jetbrains.compose.web.dom.Text("对隐私的承诺")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("隐私保护框架：")
                }
                org.jetbrains.compose.web.dom.Text(" 对于用户相关的数据传输，我们遵守隐私保护框架，确保我们维持与已建立的中国数据保护要求一致的数据保护水平。")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("对遵守地方和国际数据保护法律的承诺，确保无论数据处理在哪里，您的信息都将得到尊重和负责任的处理。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
    }
}
