package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEn : Strings {
  override val locale: String = "en"

  override val app_name: String = "Baiyan"

  override val home: String = "Home"

  override val about: String = "About"

  override val privacy: String = "Privacy"

  override val user_agreement: String = "User Agreement"

  override val terms_of_service: String = "Terms of Service"

  override val privacy_policy: String = "Privacy Policy"
}
