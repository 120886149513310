package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsHant : Strings {
  override val locale: String = "zh-rTW"

  override val app_name: String = "百言"

  override val home: String = "首頁"

  override val about: String = "關於"

  override val privacy: String = "隱私"

  override val user_agreement: String = "用戶協議"

  override val terms_of_service: String = "服務條款"

  override val privacy_policy: String = "隱私政策"
}
