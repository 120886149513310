package baiyan.app.pages.en

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun UserAgreementPage() {
    baiyan.app.components.layouts.MarkdownLayout("UserAgreement") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("user-agreement-for-baiyan") }) {
            org.jetbrains.compose.web.dom.Text("User Agreement for Baiyan")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("This User Agreement outlines the terms and conditions under which you may use the Baiyan app, an AI-powered platform designed to make language learning as engaging as browsing through TikTok.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("acceptance-of-terms") }) {
            org.jetbrains.compose.web.dom.Text("Acceptance of Terms")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("By accessing or using the Baiyan app, you agree to be bound by the terms of this User Agreement, applicable laws and regulations, and any other applicable policy. If you disagree with any part of the terms, you must cease usage of our services immediately.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("registration-and-account") }) {
            org.jetbrains.compose.web.dom.Text("Registration and Account")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("To access some features of the app, you may be required to register and create an account. By creating an account, you agree to:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Provide accurate and current information about yourself.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Maintain the confidentiality of your account and password.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Accept responsibility for all activities that occur under your account or password.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("license-to-use") }) {
            org.jetbrains.compose.web.dom.Text("License to Use")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan grants you a non-exclusive, non-transferable, revocable license to use the app solely for your personal, non-commercial purposes. This license does not include any resale or commercial use of the app or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of the app or its contents; or any use of data mining, robots, or similar data gathering and extraction tools.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("restrictions-on-use") }) {
            org.jetbrains.compose.web.dom.Text("Restrictions on Use")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("You are prohibited from using the app to:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Conduct or promote any illegal activities.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Infringe on the intellectual property rights of the app or others.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Transmit content that is bullying, defamatory, abusive, or otherwise objectionable.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the app.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("modifications-to-the-app") }) {
            org.jetbrains.compose.web.dom.Text("Modifications to the App")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan reserves the right at any time to modify or discontinue, temporarily or permanently, the app (or any part thereof) with or without notice. You agree that Baiyan shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the service.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("termination") }) {
            org.jetbrains.compose.web.dom.Text("Termination")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan may terminate or suspend your access to all or part of the app immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the User Agreement. Upon termination, your right to use the app will immediately cease.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("disclaimer-of-warranties") }) {
            org.jetbrains.compose.web.dom.Text("Disclaimer of Warranties")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("The app is provided on an \"AS IS\" and \"AS AVAILABLE\" basis. Baiyan makes no representations or warranties of any kind, express or implied, as to the operation of the app or the information, content, materials, or products included on the app.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("limitation-of-liability") }) {
            org.jetbrains.compose.web.dom.Text("Limitation of Liability")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("In no event shall Baiyan or its directors, employees, or agents be liable for any indirect, incidental, punitive, or consequential damages of any kind related to your use of the app.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("governing-law") }) {
            org.jetbrains.compose.web.dom.Text("Governing Law")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("This User Agreement shall be governed by and construed in accordance with the laws of the jurisdiction where Baiyan is based, without giving effect to any principles of conflicts of law.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("changes-to-this-agreement") }) {
            org.jetbrains.compose.web.dom.Text("Changes to This Agreement")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Baiyan reserves the right, at its sole discretion, to update, change or replace any part of this User Agreement by posting updates and changes to the app. It is your responsibility to check the app periodically for changes.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("contact-information") }) {
            org.jetbrains.compose.web.dom.Text("Contact Information")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Questions about the User Agreement should be sent to us at [")
            com.varabyte.kobweb.silk.components.navigation.Link("mailto:support@baiyan.app") {
                org.jetbrains.compose.web.dom.Text("support@baiyan.app")
            }
            org.jetbrains.compose.web.dom.Text("].")
        }
    }
}
