package baiyan.app.pages.ko

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    baiyan.app.components.layouts.MarkdownLayout("About") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("바이옌-소개") }) {
            org.jetbrains.compose.web.dom.Text("바이옌 소개")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 최첨단 AI 기술을 활용한 애플리케이션으로, 사용자가 비디오 콘텐츠를 통해 언어를 배우는 방식을 혁신하여, TikTok을 스크롤하는 것처럼 매력적이고 중독성 있게 만듭니다.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("학습") }) {
            org.jetbrains.compose.web.dom.Text("학습")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 짧고 흥미로운 비디오를 활용하여 사용자에게 다양한 언어의 정확한 발음을 가르쳐, 효과적이고 즐거운 몰입형 학습 경험을 제공합니다.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("특징") }) {
            org.jetbrains.compose.web.dom.Text("특징")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ai-생성-자막") }) {
            org.jetbrains.compose.web.dom.Text("AI 생성 자막")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 고급 AI 기술을 통해 비디오의 각 단어에 대한 정확한 자막을 자동으로 생성합니다. 이 기능은 사용자가 발음되는 단어의 철자와 의미를 볼 수 있게 함으로써 이해력과 기억력을 향상시킵니다.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("다중-언어-지원") }) {
            org.jetbrains.compose.web.dom.Text("다중 언어 지원")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("이 플랫폼은 다양한 언어를 지원하여 사용자가 직관적인 환경에서 여러 언어를 배울 수 있는 기회를 제공합니다.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("사용자-친화적-인터페이스") }) {
            org.jetbrains.compose.web.dom.Text("사용자 친화적 인터페이스")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("간단함을 염두에 두고 설계된 바이옌은 모든 사용자가 언어 학습을 접근할 수 있도록 사용자 친화적 인터페이스를 제공합니다. 사용자는 다양한 비디오를 쉽게 탐색하고 진행 상황을 추적할 수 있습니다.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("참여-도구") }) {
            org.jetbrains.compose.web.dom.Text("참여 도구")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 사용자의 동기를 유지하기 위한 다양한 도구를 포함하고 있으며, 진행 상황 추적기, 상호작용 퀴즈 및 정기적인 연습을 장려하는 일일 도전과 같은 도구가 있습니다.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("기술") }) {
            org.jetbrains.compose.web.dom.Text("기술")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 최신 AI 기술을 사용하여 비디오 형식으로 포착된 자연스러운 일상 상호작용에서 언어를 분석하고 분해하여 사용자에게 독특하고 효과적인 학습 도구를 제공합니다.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("출발점") }) {
            org.jetbrains.compose.web.dom.Text("출발점")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("바이옌은 단순한 언어 학습 앱 이상입니다; 사용자가 그들의 학습 스타일에 맞게 사용자화하고 적응할 수 있는 플랫폼입니다. 처음부터 시작하거나 기존의 언어 기술을 향상시키려는 경우, 바이옌은 모든 학습자에게 튼튼한 기반을 제공합니다.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("내보내기-및-참여") }) {
            org.jetbrains.compose.web.dom.Text("내보내기 및 참여")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("사용자가 진행함에 따라 그들의 성취를 공유하고 전 세계 학습자 커뮤니티와 상호 작용할 수 있습니다. 바이옌은 개인의 성장을 지원할 뿐만 아니라 사용자가 팁, 통찰력 및 격려를 교환할 수 있는 협력적인 환경을 조성합니다.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("오늘 바이옌과 함께 참여하여 언어 학습 경험을 즐겁고 보람 있는 여정으로 변모시키세요!")
        }
    }
}
