package baiyan.app.pages.zh

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun UserAgreementPage() {
    baiyan.app.components.layouts.MarkdownLayout("UserAgreement") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("百言用户协议") }) {
            org.jetbrains.compose.web.dom.Text("百言用户协议")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("本用户协议概述了您可以在何种条件下使用百言应用程序的条款和条件，百言是一个由AI驱动的平台，旨在使语言学习与浏览TikTok一样引人入胜。")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("接受条款") }) {
            org.jetbrains.compose.web.dom.Text("接受条款")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("通过访问或使用百言应用，您同意受本用户协议的条款、适用的法律和规章以及其他相关政策的约束。如果您不同意条款的任何部分，您必须立即停止使用我们的服务。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("注册和账户") }) {
            org.jetbrains.compose.web.dom.Text("注册和账户")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("为了访问应用的某些功能，您可能需要注册并创建一个账户。通过创建账户，您同意：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("提供关于您自己的准确和最新的信息。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("维护您账户和密码的保密性。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("接受对您账户或密码下发生的所有活动负责。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("使用许可") }) {
            org.jetbrains.compose.web.dom.Text("使用许可")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言授予您一个非独占的、不可转让的、可撤销的许可，仅供您个人、非商业目的使用应用。此许可不包括对应用或其内容的任何转售或商业使用；任何产品列表、描述或价格的收集和使用；对应用或其内容的任何衍生使用；或任何使用数据挖掘、机器人或类似数据收集和提取工具的使用。")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("使用限制") }) {
            org.jetbrains.compose.web.dom.Text("使用限制")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("您被禁止使用应用来：")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("进行或促进任何非法活动。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("侵犯应用或他人的知识产权。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("传播欺凌性、诽谤性、辱骂性或其他令人反感的内容。")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("上传或传输病毒或任何其他类型的恶意代码，这些代码将或可能以任何方式影响应用的功能或运作。")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("对应用的修改") }) {
            org.jetbrains.compose.web.dom.Text("对应用的修改")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言保留随时修改或永久性或临时性中断应用（或其任何部分）的权利，恕不另行通知。您同意百言对您或任何第三方不承担因任何修改、暂停或中断服务造成的责任。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("终止") }) {
            org.jetbrains.compose.web.dom.Text("终止")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言可以立即终止或暂停您对应用全部或部分的访问权，无需事先通知或承担责任，原因包括但不限于您违反用户协议。终止后，您使用应用的权利将立即停止。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("免责声明") }) {
            org.jetbrains.compose.web.dom.Text("免责声明")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("应用是在“现状”和“可用”基础上提供的。百言不对应用的运行或应用上包含的信息、内容、材料或产品作出任何形式的明示或暗示的陈述或保证。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("责任限制") }) {
            org.jetbrains.compose.web.dom.Text("责任限制")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("在任何情况下，百言或其董事、雇员或代理人均不对与您使用应用相关的任何间接的、偶然的、惩罚性的或结果性的损害承担责任。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("管辖法律") }) {
            org.jetbrains.compose.web.dom.Text("管辖法律")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("本用户协议应受百言所在地法律的管辖，并依据该法律解释，不考虑法律冲突的原则。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("对本协议的更改") }) {
            org.jetbrains.compose.web.dom.Text("对本协议的更改")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("百言保留通过在应用上发布更新和更改来单方面更新、更改或替换本用户协议任何部分的权利。检查应用以获取更改的责任在于您。")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("联系信息") }) {
            org.jetbrains.compose.web.dom.Text("联系信息")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("有关用户协议的问题应发送至 [")
            com.varabyte.kobweb.silk.components.navigation.Link("mailto:support@baiyan.app") {
                org.jetbrains.compose.web.dom.Text("support@baiyan.app")
            }
            org.jetbrains.compose.web.dom.Text("]。")
        }
    }
}
